import Image from 'next/image';
import ErrorImage from '../Assets/Images/404Image.png';
import { Grid, Typography } from '@mui/material';
export default function Custom404() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '680px',
      }}
    >
      <Grid style={{ textAlign: 'center' }}>
        <Image loading="lazy" src={ErrorImage} alt="error image"></Image>
        <Typography className="fs-20 font-semibold" variant="h1">
          Sorry! Page Not found :(
        </Typography>
        <Typography className="fs-14" sx={{ fontWeight: '400' }}>
          Sorry, We can't find the page you're looking for.
        </Typography>
      </Grid>
    </div>
  );
}
